import React from "react";

import Modal from "@eman/emankit/Modal";

import ResourceComponent from "@component/ResourceComponent";

/**
 * Base parent for Create, Show and Edit modals
 */
export default abstract class BaseModal<
  TModel extends models.Base
> extends ResourceComponent<PropsFromRouter> {
  modalWidth: number = 1000;

  abstract vm: ViewModel.Base<TModel, Repository<TModel>>;

  abstract renderModalBody(): React.ReactNode;

  abstract onClose(id?: string | number | undefined): void;

  abstract successMessage(): string;

  title(): string {
    return this.tg("title");
  }

  buttons(): React.ReactNode {
    // to be overloaded in children if needed
    return null;
  }

  getId() {
    const params = this.props.match ? this.props.match.params : {};
    // tslint:disable-next-line: no-string-literal
    const key = `${this.vm.repository["modelName"]}_id`; // TODO: This hack I personaly dont like it... but it will works for sub-show / list vm either..
    return params[key];
  }

  render() {
    const buttons = () => {
      return <div style={{ textAlign: "right" }}>{this.buttons()}</div>;
    };

    if (!this.vm.entity) {
      return null;
    }

    return (
      <>
        <Modal
          className="visible_overflow"
          open={true}
          onClose={this.onClose}
          style={{ width: this.modalWidth }}
          buttons={buttons}
          header={this.title()}
        >
          {this.renderModalBody()}
        </Modal>
        {/*<Prompt*/}
        {/*when={this.vm.entity.changes > 0}*/}
        {/*message={localize('form.unsaved_prompt')}*/}
        {/*/>*/}
      </>
    );
  }
}
