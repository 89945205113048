// Models
import CurrentUser from "@model/CurrentUser";
import EquipmentGroup from "@model/EquipmentGroup";
import EquipmentType from "@model/EquipmentType";
import User from '@model/User';
// List VMs
import EquipmentGroupListVM from "@vm/List/EquipmentGroup";
import EquipmentTypeListVM from "@vm/List/EquipmentType";
import UserListVM from "@vm/List/User";
// Repositories
import CurrentUserRepository from "@repository/CurrentUser";
import EquipmentGroupRepository from "@repository/EquipmentGroup";
import EquipmentTypeRepository from "@repository/EquipmentType";
import UserRepository from '@repository/User';
// Services
import Breadcrumb from '@service/Breadcrumb';
import CurrentUserService from '@service/CurrentUser';
import Router from '@service/Router';
// Uri helpers
import UriHelper from '@util/UriHelper';
// Create VMs
import EquipmentGroupCreateVM from "@vm/Create/EquipmentGroup";
import UserCreateVM from '@vm/Create/User';
// Edit List VMs
// Edit VMs
import EquipmentGroupEditVM from '@vm/Edit/EquipmentGroup';
import EquipmentTypeEditVM from "@vm/Edit/EquipmentType";
import UserEditVM from '@vm/Edit/User';

// Form VMs
import EquipmentTypeFormVM from "@vm/Form/EquipmentType";

// Other VMs
import ForSelectVM from '@vm/Other/ForSelect';
// Show VMs
import UserShowVM from "@vm/Show/User";

import * as history from 'history'
import { Container, inject, injectable, interfaces } from 'inversify';
import getDecorators from 'inversify-inject-decorators';
import 'reflect-metadata';
import { uriHelper } from './config';
// Types
import TYPES from './inversify.types';
// VMs

const container = new Container();

// Generate container and add decorators
const {lazyInject} = getDecorators(container);

// Generate router first place
const routerHistory = history.createHashHistory({basename: '/'});
const router = new Router(routerHistory);

/**
 * !!! SORT Repositories, Services a VMs alphabetically, it prevents to merge conflicts !!!
 */

// Repositories
container.bind<AbstractCurrentUserRepository<CurrentUser>>(TYPES.CurrentUserRepository).to(CurrentUserRepository).inSingletonScope();
container.bind<Repository<EquipmentGroup>>(TYPES.EquipmentGroupRepository).to(EquipmentGroupRepository).inSingletonScope();
container.bind<Repository<EquipmentType>>(TYPES.EquipmentTypeRepository).to(EquipmentTypeRepository).inSingletonScope();
container.bind<Repository<User>>(TYPES.UserRepository).to(UserRepository).inSingletonScope();

// Services
container
  .bind<Breadcrumb>(TYPES.Breadcrumb)
  .to(Breadcrumb)
  .inSingletonScope();
container
  .bind<CurrentUserService>(TYPES.User)
  .to(CurrentUserService)
  .inSingletonScope();
container.bind<Router>(TYPES.Router).toConstantValue(router);

// VMs
// Create VMs
container.bind<EquipmentGroupCreateVM>(TYPES.EquipmentGroupCreate).to(EquipmentGroupCreateVM);
container.bind<UserCreateVM>(TYPES.UserCreate).to(UserCreateVM);

// List VMs
container.bind<UserListVM>(TYPES.UserList).to(UserListVM);
container.bind<EquipmentGroupListVM>(TYPES.EquipmentGroupList).to(EquipmentGroupListVM);
container.bind<EquipmentTypeListVM>(TYPES.EquipmentTypeList).to(EquipmentTypeListVM);
container.bind<UserListVM>(TYPES.UserList).to(UserListVM);

// Items VMs

// Show VMs
container.bind<UserShowVM>(TYPES.UserShow).to(UserShowVM);

// Edit VMs
container.bind<EquipmentGroupEditVM>(TYPES.EquipmentGroupEdit).to(EquipmentGroupEditVM);
container.bind<EquipmentTypeEditVM>(TYPES.EquipmentTypeEdit).to(EquipmentTypeEditVM);
container.bind<UserEditVM>(TYPES.UserEdit).to(UserEditVM);

// Form VMs
container.bind<EquipmentTypeFormVM>(TYPES.EquipmentTypeForm).to(EquipmentTypeFormVM);

// Other VMs
container.bind<interfaces.Factory<ForSelectVM<models.Base>>>(TYPES.ForSelect).toFactory<ForSelectVM<models.Base>>(() => {
  return (repository: any, propertyName?: string[], order?: { direction: string, field: string }, pagination?: { page: number, pageSize: number }) => {
    return new ForSelectVM(repository, propertyName, order, pagination);
  };
});

// Others (uri etc..
container.bind<UriHelper>(TYPES.UriHelper).toConstantValue(uriHelper);

// EditList VMs

export { container, lazyInject, TYPES, inject, injectable };
