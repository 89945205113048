import { injectable } from "inversify";
import { observable } from "mobx";

@injectable()
export default abstract class FormViewModel<TModel extends models.Base> {
  @observable protected entity: TModel;

  setEntity(entity: TModel) {
    this.entity = entity;
  }
}
