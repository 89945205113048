import React from "react";
import { observer } from "mobx-react";


import EquipmentType from "@model/EquipmentType";
import EquipmentTypeForm from "@view/EquipmentType/Form";
import EquipmentTypeEditVM from "@vm/Edit/EquipmentType";
import EquipmentTypeFormVM from "@vm/Form/EquipmentType";

import EditModal from "@component/EditModal";

import { lazyInject } from "../../inversify.config";
import TYPES from "../../inversify.types";

@observer
export default class EquipmentTypeModal extends EditModal<EquipmentType> {
  modelName = "equipmentType";

  @lazyInject(TYPES.EquipmentTypeEdit)
  vm: EquipmentTypeEditVM;

  @lazyInject(TYPES.EquipmentTypeForm)
  formVM: EquipmentTypeFormVM;


  getId(): any {
    const params = this.props.match ? this.props.match.params : {};
    return Number(params.type_id);
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.types());
  };

  successMessage(): string {
    return `Equipment type has been successfully updated.`;
  }

  renderModalBody() {
    return <EquipmentTypeForm entity={this.vm.entity} vm={this.formVM} />;
  }
}
