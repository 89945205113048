import { format } from "date-fns";
import BaseComponent from "@component/BaseComponent";
import { localize } from "@util/Localization";
import { FE_DATE_FORMAT, parseDate } from "@util/DateFormats";

export default abstract class ResourceComponent<
  TProps = {},
  TState = {}
> extends BaseComponent<TProps, TState> {
  abstract get modelName(): string;

  prefix: string = "";

  suffix: string = "";

  externalLocs: Record<string, string> = {};

  get nameSpace(): string {
    let nameSpace = this.modelName;

    if (this.prefix) {
      nameSpace = `${this.prefix}.${this.modelName}`;
    }

    if (this.suffix) {
      nameSpace += `.${this.suffix}`;
    }

    return nameSpace;
  }

  /**
   * Translate atribute according to current model.
   *
   * @param attribute
   */
  ta = (attribute: string): string => {
    return localize(`${this.modelName}.${attribute}`, this.externalLocs);
  };

  /**
   * Translate global code according to current nameSpace
   *
   * @param code
   */
  tg = (code: string): string => {
    return localize(`${this.nameSpace}.${code}`, this.externalLocs);
  };

  /**
   * Return date in proper format according to localization.
   *
   * @param {Date | string} date
   * @returns {string}
   * @memberof LocalizationService
   */
  formatDate(date?: string | Date): string {
    let parsedDate: Date;

    if (!date) {
      return "";
    } else if (typeof date === "string") {
      parsedDate = parseDate(date);
    } else {
      parsedDate = date;
    }

    return format(parsedDate, FE_DATE_FORMAT);
  }

  /**
   * Return time in proper format according to localization.
   *
   * @param {Date | string} time
   * @returns {string}
   * @memberof LocalizationService
   */
  formatTime(time: string | Date): string {
    let parsedTime: Date;
    if (typeof time === "string") {
      parsedTime = parseDate(time);
    } else {
      parsedTime = time;
    }

    return parsedTime.toLocaleTimeString(navigator.language || "cs-CZ", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  formatDateTime(date?: string | Date): string {
    return date ? `${this.formatTime(date)} ${this.formatDate(date)}` : "";
  }
}
