import { Exclude } from "@eman/class-transformer";
import { observable } from "mobx";
import BaseModel from "src/Models/BaseModel";

export default class EquipmentType extends BaseModel {
  @observable equipmentTypeId: number;
  @observable equipmentTypeName: string;
  @observable equipmentGroupId: number;

  @Exclude()
  validationOptions = {
    equipmentTypeName: {
      required: true,
    },
    equipmentGroupId: {
      required: true,
    },
  };
}
