import EditModal from "@component/EditModal";
import EquipmentGroup from "@model/EquipmentGroup";
import EquipmentGroupForm from "@view/EquipmentGroup/Form";
import EquipmentGroupEditVM from "@vm/Edit/EquipmentGroup";
import { observer } from "mobx-react";
import React from "react";
import { lazyInject } from "../../inversify.config";
import TYPES from "../../inversify.types";
@observer
export default class EquipmentGroupModal extends EditModal<EquipmentGroup> {
  modelName = "equipmentGroup";

  @lazyInject(TYPES.EquipmentGroupEdit)
  vm: EquipmentGroupEditVM;


  getId(): any {
    const params = this.props.match ? this.props.match.params : {};
    return Number(params.group_id);
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.groups());
  };

  successMessage(): string {
    return `Equipment group has been successfully updated.`;
  }

  renderModalBody() {
    return <EquipmentGroupForm entity={this.vm.entity} />;
  }
}
