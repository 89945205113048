import ResourceComponent from "@component/ResourceComponent";
import Form, { GenericFormField } from "@util/Form";
import { Tooltip } from "react-tippy";
import React from "react";
import Icon, { IconType } from "@eman/emankit/Icon";

export default abstract class BaseForm<
  TModel extends models.Base,
  OtherProps = {},
  OtherState = {}
> extends ResourceComponent<
  {
    entity: TModel;
    onFormSubmit?: () => void;
    onFormReset?: () => void;
  } & OtherProps,
  OtherState
> {
  suffix = "form";

  constructor(props: any) {
    super(props);

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  renderFormBody(): React.ReactNode {
    return null;
  }

  generateTitleWithTooltip = (title: React.ReactNode, tooltip: string) => {
    const hintMessage = (
      <Tooltip
        html={(
          <div style={{ width: 250, padding: 20, textAlign: 'left'}}>
            {tooltip}
          </div>
        )}
        arrow={true}
        position="right"
        animation="shift"
        style={{ display: "inline-block" }}
        className="emankit__label_tooltip"
      >
        <Icon
          icon={IconType.SoconInfo}
          width={16}
          height={16}
        />
      </Tooltip>
    );
    return (
      <div className="d-flex">
        <div className="p-2">{title}</div>
        <div className="p-2" style={{ marginLeft: '4px', marginTop: '-1px' }}>{hintMessage}</div>
      </div>
    );
  };

  generateFormField = (
    property: string,
    formField: React.ReactNode,
    required?: boolean,
    label?: string,
    subEntity?: models.Base,
    headerError?: boolean,
    disabled?: boolean,
    tooltip?: string
  ) => {
    return (
      <GenericFormField
        key={property}
        target={subEntity ? subEntity : this.props.entity}
        property={property}
        label={label}
        required={required}
        disabled={disabled}
        headerError={headerError}
        hint={tooltip}
      >
        {formField}
      </GenericFormField>
    );
  };

  render() {
    const { onFormReset } = this.props;

    return (
      <Form onFormSubmit={this.onFormSubmit} onFormReset={onFormReset}>
        {this.renderFormBody()}
      </Form>
    );
  }

  onFormSubmit() {
    const { onFormSubmit } = this.props;
    if (onFormSubmit) {
      onFormSubmit();
    }
  }
}
