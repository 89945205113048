import { inject, injectable } from "inversify";
import EquipmentType from "@model/EquipmentType";
import EquipmentGroup from "@model/EquipmentGroup";
import FormViewModel from "@vm/Form/FormViewModel";

import TYPES from "../../inversify.types";
import { action, observable } from "mobx";

@injectable()
export default class SchoolFormVM extends FormViewModel<EquipmentType> {
  @observable typesList: EquipmentGroup[] = [];

  constructor(
    @inject(TYPES.EquipmentGroupRepository)
    private repository: Repository<EquipmentGroup>
  ) {
    super();
    this.init();
  }

  @action.bound
  async init() {
    const result = await this.repository.fetchList({});
    if (result) {
      this.typesList = result.list;
    }
  }

  get options(): FormUtils.SelectOption[] {
    return this.typesList.map((group) => ({
      value: group.equipmentGroupId,
      label: group.name,
    }));
  }
}
