import BaseRouter from "./Base";
import React from "react";
import { uriHelper } from "../config";
import EquipmentTypeList from "@view/EquipmentType/List";
import EquipmentTypeEdit from "@view/EquipmentType/Edit";

export const equipmentTypeRoutes: AppRoute[] = [
  {
    path: uriHelper.types_uri(),
    exact: false,
    component: EquipmentTypeList,
  },
  {
    path: uriHelper.edit_types_uri(),
    exact: true,
    modalComponent: EquipmentTypeEdit,
  },
];

export default class EquipmentType extends BaseRouter {
  render() {
    return (
      <div className="emankit__layout__wrapper">
        <div className="emankit__layout__wrapper_content">
          {this.pageRouteRender(equipmentTypeRoutes)}
        </div>
      </div>
    );
  }
}
