import { inject, injectable } from "inversify";

import EquipmentGroup from "@model/EquipmentGroup";
import CreateViewModel from "@vm/Create/CreateViewModel";
import TYPES from "../../inversify.types";

@injectable()
export default class EquipmentGroupVM extends CreateViewModel<
  EquipmentGroup,
  Repository<EquipmentGroup>
> {
  constructor(
    @inject(TYPES.EquipmentGroupRepository)
    repository: Repository<EquipmentGroup>
  ) {
    super(EquipmentGroup, repository);
  }

  async createGroup(): Promise<EquipmentGroup | undefined> {
    this.currentlyFetching = true;
    const response = await this.repository.create(this.entity);
    this.currentlyFetching = false;

    return response && response.original ? response.original : undefined;
  }
}
