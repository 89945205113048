import { observer } from "mobx-react";
import React from "react";

import CreateModal from "@component/CreateModal";
import EquipmentGroup from "@model/EquipmentGroup";
import EquipmentGroupCreateVM from "@vm/Create/EquipmentGroup";

import EquipmentGroupForm from "./Form";
import { lazyInject, TYPES } from "../../inversify.config";
import ValidationManager from "@util/Form/ValidationManager";
import EventBus, { SHOW_TOAST } from "@util/EventBus";

@observer
export default class EquipmentGroupCreate extends CreateModal<EquipmentGroup> {
  modelName = "equipmentGroup";

  @lazyInject(TYPES.EquipmentGroupCreate)
  vm: EquipmentGroupCreateVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.groups());
  };

  successMessage(): string {
    return `Equipment group has been successfully created.`;
  }

  onSave = async () => {
    ValidationManager.formValidation(
      this.vm.entity,
      this.vm.entity.validationOptions
    );

    if (this.vm.entity.errorCount !== 0) {
      return;
    }

    const entity = await this.vm.createGroup();

    if (!!entity) {
      EventBus.trigger(SHOW_TOAST, this.successMessage());
      this.onClose();
    }

    return status;
  };

  renderModalBody() {
    return <EquipmentGroupForm entity={this.vm.entity} />;
  }
}
