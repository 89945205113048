import UriHelper from '@util/UriHelper';

export const EXPORT_MAX_RECORDS = 2000;

export const routes = {
  groups: {
    key: 'group_id',
  },
  types: {
    key: 'type_id',
  },
};

export const uriHelper = new UriHelper(routes as any) as any;
