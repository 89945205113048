import React from "react";
import { observer } from "mobx-react";

import { confirmAlert } from "@eman/emankit/ConfirmAlert";
import Icon, { IconType } from "@eman/emankit/Icon";
import Button, { ButtonVariant } from "@eman/emankit/Button";
import { HeaderProps } from "@eman/emankit/DataTable/HeaderBar";

import BaseList from "@component/BaseList";
import EquipmentGroup from "@model/EquipmentGroup";
import EquipmentGroupListVM from "@vm/List/EquipmentGroup";

import { lazyInject, TYPES } from "../../inversify.config";

@observer
export default class EquipmentGroupList extends BaseList<
  EquipmentGroup,
  EquipmentGroupListVM,
  {},
  {}
> {
  modelName = "equipmentGroup";
  emptyRow = this.tg("table.noResults");
  searchable = false;
  selectableRows = false;

  @lazyInject(TYPES.EquipmentGroupList)
  vm: EquipmentGroupListVM;

  constructor(props: any) {
    super(props);

    this.state = {};
  }

  add = () => {
    this.router.pageLink(this.uriHelper.new_groups());
  };

  edit = (group: EquipmentGroup) => () => {
    this.router.pageLink(this.uriHelper.edit_groups(group.equipmentGroupId));
  };

  delete = (group: EquipmentGroup) => () => {
    confirmAlert({
      title: this.tg("delete.title"),
      message: this.tg("delete.message"),
      buttons: [
        {
          id: "cancel",
          label: this.tg("delete.cancel"),
          variant: ButtonVariant.SecondaryWhite,
        },
        {
          id: "ok",
          label: this.tg("delete.ok"),
          variant: ButtonVariant.Primary,
        },
      ],
      onClick: (id: string) =>
        id === "ok"
          ? this.vm.destroy(
              group.equipmentGroupId,
              "Equipment group has been successfully deleted."
            )
          : null,
    });
  };

  headerProps(): HeaderProps | undefined {
    return {
      text: this.tg("table.title"),
      buttons: () => (
        <Button variant={ButtonVariant.Primary} onClick={this.add}>
          {this.tg("table.add")}
        </Button>
      ),
    };
  }

  renderRow = (item: EquipmentGroup, column: string) => {
    switch (column) {
      case "registeredOn":
        return this.formatDateTime(item.registeredOn);
      case "actions":
        return (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Icon
              icon={IconType.SoconEdit}
              height={20}
              width={20}
              key="group_edit"
              onClick={this.edit(item)}
            />
            <Icon
              icon={IconType.SoconReject}
              height={20}
              width={20}
              key="group_delete"
              onClick={this.delete(item)}
            />
          </div>
        );
      default:
        return item[column];
    }
  };

  paginationProps() {
    return {
      pageSize: this.vm.pagination.pageSize,
      page: this.vm.pagination.page,
      totalRecords: this.vm.total,
      onPageChange: this.onPageChange,
      label: "results",
    };
  }

  filterProps() {
    return {
      hideFilterSettings: true,
      hideColumnSettings: true,
      hideReset: true,
      filters: [],
    };
  }

  dataProps() {
    const columns = [
      this.createField("equipmentGroupId", {
        width: 40,
        tooltip: true,
        nosort: true,
      }),
      this.createField("name", { width: 200, tooltip: true, nosort: true }),
      this.createField("rank", { width: 200, tooltip: true, nosort: true }),
      this.createField("registeredOn", {
        width: 80,
        tooltip: true,
        nosort: true,
      }),
      this.createField("actions", { width: 80, nosort: true, hideLabel: true }),
    ];

    if (this.vm.columns.length === 0) {
      this.vm.setColumns(columns.map((column) => column.id));
    }

    return {
      columns,
      value: this.renderRow,
      loading: false,
      fixedHeader: true,
    };
  }
}
