import { inject, injectable } from "inversify";

import EquipmentType from "@model/EquipmentType";
import ListViewModel from "@vm/List/ListViewModel";
import TYPES from "../../inversify.types";
import EquipmentGroup from "@model/EquipmentGroup";
import { action, observable } from "mobx";

@injectable()
export default class EquipmentTypeListVM extends ListViewModel<
  EquipmentType,
  Repository<EquipmentType>
> {
  static defaults = {
    order: {
      field: "last_name",
      direction: "asc",
    },
    columns: [],
    visibleFilters: [],
  };

  @observable private groups: EquipmentGroup[] = [];

  constructor(
    @inject(TYPES.EquipmentTypeRepository)
    repository: Repository<EquipmentType>,
    @inject(TYPES.EquipmentGroupRepository)
    private groupRepository: Repository<EquipmentGroup>
  ) {
    super(repository);
  }

  @action.bound
  async init(...rest: any) {
    const result = await this.groupRepository.fetchList({});
    if (result) {
      this.groups = result.list;
    }
    return this.fetchList();
  }

  @action
  getGroupNameById(id: number) {
    // tslint:disable-next-line: no-console
    const group = this.groups.find((g) => g.equipmentGroupId === id);
    if (group) {
      return group.name;
    }

    return id;
  }
}
