// Ui components
import Layout from "@component/Layout";
import PageRoute from "@component/PageRoute";
// Views
import { observer } from "mobx-react";
import React from "react";
import { Router } from "react-router";
// Others
import { uriHelper } from "../config";
// Base components
import BaseRouter from "./Base";

import Cookies from "js-cookie";

// Routes
import EquipmentGroupRoutes from "./EquipmentGroup";
import EquipmentTypeRoutes from "./EquipmentType";

const parseQuery = (queryString: string): any => {
  const query = {};
  const pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  pairs.forEach((pair: string) => {
    const tempPair = pair.split("=");
    query[decodeURIComponent(tempPair[0])] = decodeURIComponent(
      tempPair[1] || ""
    );
  });

  return query;
};

const appRoutes: AppRoute[] = [
  {
    path: "/",
    exact: true,
    redirect: () => uriHelper.groups_uri(),
  },
  {
    path: uriHelper.groups_uri(),
    exact: false,
    title: "group.title",
    component: EquipmentGroupRoutes,
  },
  {
    path: uriHelper.types_uri(),
    exact: false,
    title: "type.title",
    component: EquipmentTypeRoutes,
  },
];

@observer
export default class RootRouter extends BaseRouter {
  render() {
    let userSection = null;
    if (window.location.search && window.location.search.includes("token")) {
      Cookies.set("MRM_TOKEN", parseQuery(window.location.search).token);
      this.user.decodeUserFromToken();
      window.location.href = "#/";
    }

    if (this.user.isLoggedIn) {
      userSection = <>{this.pageRouteRender(appRoutes)}</>;
    } else {
      this.user.loginUser();
    }

    return (
      <Router history={this.router.history}>
        <Layout>
          {/* Default path */}
          <PageRoute path="/" />
          {/* User or not user section */}
          {userSection}
        </Layout>
      </Router>
    );
  }
}
