import axios, { AxiosRequestConfig } from "axios";
import EventBus, {
  HIDE_LOADING,
  SHOW_ERROR,
  SHOW_LOADING,
  START_REQUEST,
  STOP_REQUEST
} from "./EventBus";
import { B2B_CONNECTION } from 'src';

import Cookies from "js-cookie";

const API_KEY = "17134397-095A-470D-9ABF-C994CEC296CB";

// Set defaults
axios.defaults.baseURL = process.env.REACT_APP_API_BASE;

export interface AxiosConfig extends AxiosRequestConfig {
  url: string;
  id: string;
  loading?: boolean;
  ignoreErrors?: boolean;
}

class ApiClient {
  /**
   * This is usefull for Create / Update statements, it fetch data checks their validity
   * and returns normalized api response.
   */
  fetchResponse = (config: AxiosConfig, withTimestamp: boolean = true): Promise<ApiResponse<any>> => {
    return this.fetchData(config, withTimestamp)
      .then(response => {
        return {
          status: true,
          entity: response.entity,
          original: response
        };
      })
      .catch(error => {
        return {
          status: false,
          errors: error.response && error.response.data && error.response.data.errors
            ? error.response.data.errors
            : error.response ? error.response.data : error,
          original: error.response
        };
      });
  };

  // Default error handler
  errorHandler = (config: AxiosConfig, error: any) => {
    if (process.env.NODE_ENV === "development") {
      // tslint:disable-next-line:no-console
      console.error(error);
    }

    const response = error.response;

    // Network error!
    if (!response) {
      if (config.ignoreErrors !== true) {
        EventBus.trigger(SHOW_ERROR, {
          code: "errors.unknown_error",
          single: true
        });
      }
      return error;
    }

    const status = response.status;

    let errorMessage: string | ErrorMessage = "";

    switch (status) {
      case 400:
        // Bad request
        errorMessage = response.data.error;

        if (response.data.errors && Array.isArray(response.data.errors)) {
          // Test for array
          errorMessage = response.data.errors[0];
        }
        break;

      case 401:
        // alert('Unauthorized')
        // Unauthorized - logout user
        Cookies.remove("MRM_TOKEN");
        window.location.assign(B2B_CONNECTION);
        break;

      case 403: // Unauthorized access
        errorMessage = response.data.ErrorDescription
          ? response.data.ErrorDescription
          : "errors.unknown_error";
        break;

      case 404:
        errorMessage = response.data.ErrorDescription
          ? response.data.ErrorDescription
          : "errors.unknown_error";
        break;
      case 409: // Conflict
        errorMessage = response.data.ErrorDescription
          ? response.data.ErrorDescription
          : "errors.unknown_error";
        break;
      case 500:
        // Internal
        if (response.data.ErrorDescription) {
          errorMessage = response.data.ErrorDescription;
        } else {
          // This is for HTML error returned by server
          errorMessage = { code: "errors.unknown_error" };
        }
        break;
    }

    if (String(errorMessage).length > 0 && config.ignoreErrors !== true) {
      EventBus.trigger(SHOW_ERROR, errorMessage);
    }

    return error;
  };

  fetchDataWithoutAuth(config: AxiosConfig): Promise<any> {
    config.params = {
      timestamp: new Date().getTime(),
      ...config.params
    };
    const axiosConfig = {
      headers: {
        ApiKey: "krl5xeiijxtg",
        Accept: "application/json",
        "Content-Type":
          (config.headers && config.headers["Content-type"]) ||
          "application/json",
        "Cache-Control": "no-cache",
        // "Access-Control-Allow-Headers": "Content-Type, Accept"
      },
      method: "get",
      ...config
    } as any;

    if (!axiosConfig.params) {
      axiosConfig.params = {};
    }

    if (config.loading === undefined || config.loading) {
      EventBus.trigger(SHOW_LOADING, config.id);
    }
    EventBus.trigger(START_REQUEST, config.id);

    return axios
      .request(axiosConfig)
      .catch(reason => {
        if (config.loading !== false) {
          EventBus.trigger(HIDE_LOADING, config.id);
        }
        EventBus.trigger(STOP_REQUEST, config.id);

        if (!config.cancelToken) {
          throw this.errorHandler(config, reason);
        } else {
          return reason
        }
      })
      .then(response => {
        if (config.loading !== false) {
          EventBus.trigger(HIDE_LOADING, config.id);
        }
        EventBus.trigger(STOP_REQUEST, config.id);
        return response.data;
      });
  }

  /**
   * This method calls anyhting, not parse it and raise error for all not OK responses.
   * @param config Axios configuration
   */
  fetchData(config: AxiosConfig, withTimestamp: boolean = true): Promise<any> {
    config.params = {
      ...(withTimestamp && { timestamp: new Date().getTime() }),
      ...config.params
    };
    const axiosConfig = {
      headers: {
        // Authorization: `Bearer ${Cookies.get("MRM_TOKEN")}`,
        "X-API-KEY": API_KEY,
        Accept: "application/json",
        "Content-Type":
          (config.headers && config.headers["Content-type"]) ||
          "application/json",
        "Cache-Control": "no-cache",
        // "Access-Control-Allow-Headers": "Content-Type, Accept"
      },
      method: "get",
      ...config
    } as any;

    if (!axiosConfig.params) {
      axiosConfig.params = {};
    }

    if (config.loading === undefined || config.loading) {
      EventBus.trigger(SHOW_LOADING, config.id);
    }
    EventBus.trigger(START_REQUEST, config.id);

    return axios
      .request(axiosConfig)
      .catch(reason => {
        if (config.loading !== false) {
          EventBus.trigger(HIDE_LOADING, config.id);
        }
        EventBus.trigger(STOP_REQUEST, config.id);

        if (!config.cancelToken) {
          throw this.errorHandler(config, reason);
        } else {
          return reason
        }
      })
      .then(response => {
        if (config.loading !== false) {
          EventBus.trigger(HIDE_LOADING, config.id);
        }
        EventBus.trigger(STOP_REQUEST, config.id);
        return response.data;
      });
  }
}

export default new ApiClient();
