import React from "react";

import EquipmentGroupList from "@view/EquipmentGroup/List";
import EquipmentGroupCreate from "@view/EquipmentGroup/Create";
import EquipmentGroupEdit from "@view/EquipmentGroup/Edit";

import BaseRouter from "./Base";
import { uriHelper } from "../config";

export const equipmentGroupRoutes: AppRoute[] = [
  {
    path: uriHelper.groups_uri(),
    exact: false,
    component: EquipmentGroupList,
  },
  {
    path: uriHelper.new_groups_uri(),
    exact: true,
    modalComponent: EquipmentGroupCreate,
  },
  {
    path: uriHelper.edit_groups_uri(),
    exact: true,
    modalComponent: EquipmentGroupEdit,
  },
];

export default class EquipmentGroup extends BaseRouter {
  render() {
    return (
      <div className="emankit__layout__wrapper">
        <div className="emankit__layout__wrapper_content">
          {this.pageRouteRender(equipmentGroupRoutes)}
        </div>
      </div>
    );
  }
}
