import { Exclude, Transform, Type } from '@eman/class-transformer';
import { observable } from "mobx";
import BaseModel from "src/Models/BaseModel";
import EquipmentType from './EquipmentType';

export default class EquipmentGroup extends BaseModel {
  @observable equipmentGroupId: number;
  @observable name: string;
  @observable rank: number;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable registeredOn: Date;
  @observable equipmentTypes: EquipmentType[];

  @Exclude()
  validationOptions = {
    name: {
      required: true,
    },
    rank: {
      required: true,
    },
  }
}
