import { classToPlain } from "@eman/class-transformer";
import EquipmentType from "@model/EquipmentType";
import ApiClient from "@util/ApiClient";
import EventBus, { ENTITY_UPDATE } from "@util/EventBus";
import { injectable } from "inversify";
import BaseRepository from "./BaseRepository";

@injectable()
export default class EquipmentTypeRepository extends BaseRepository<
  EquipmentType
> {
  get classModelName(): string {
    return "equipmentType";
  }

  constructor() {
    super(EquipmentType, "admin/equipment_types", "equipment_type");
  }

  update(id: number | string | null, object: EquipmentType, params: any = {}) {
    const config = {
      url: `admin/equipment_type`,
      method: "PUT" as "PUT",
      id: `UPDATING_${this.modelName.toUpperCase()}`,
      params,
      data: classToPlain(object),
    };

    return ApiClient.fetchResponse(config).then((response) => {
      EventBus.trigger(ENTITY_UPDATE, {
        identificator: this.classModelName,
        id,
      });

      return response;
    });
  }
}
