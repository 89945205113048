const TYPES = {
  // Services
  Breadcrumb: Symbol("Breadcrumbs"),
  User: Symbol("CurrentUser"),
  Router: Symbol("Router"),

  // URI Helpers
  UriHelper: Symbol("UriHelper"),

  // Repositories
  CurrentUserRepository: Symbol("CurrentUserRepository"),
  EquipmentGroupRepository: Symbol("EquipmentGroupRepository"),
  EquipmentTypeRepository: Symbol("EquipmentTypeRepository"),
  UserRepository: Symbol("UserRepository"),

  // List VMs
  EquipmentGroupList: Symbol("EquipmentGroupListVM"),
  EquipmentTypeList: Symbol("EquipmentTypeListVM"),
  UserList: Symbol("UserListVM"),

  // Items VMs

  // Show VMs
  UserShow: Symbol("UserShowVM"),

  // Create VMs
  EquipmentGroupCreate: Symbol("EquipmentGroupCreateVM"),
  UserCreate: Symbol("UserCreateVM"),

  // Edit VMs
  EquipmentGroupEdit: Symbol("EquipmentGroupEditVM"),
  EquipmentTypeEdit: Symbol("EquipmentTypeEditVM"),
  UserEdit: Symbol("UserEditVM"),

  // Edit List VM

  // Form VMs
  EquipmentTypeForm: Symbol("EquipmentTypeFormVM"),

  // Other
  ForSelect: Symbol("ForSelectVM"),
};

export default TYPES;
