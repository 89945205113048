import { observer } from "mobx-react";
import React from "react";

import BaseForm from "@component/BaseForm";
import EquipmentGroup from "@model/EquipmentGroup";
import { TextBox } from "@util/Form";

@observer
export default class EquipmentGroupForm extends BaseForm<EquipmentGroup> {
  modelName = "equipmentGroup";

  renderFormBody() {
    return (
      <div className="row">
        <div className="col-md-8">
          {this.generateFormField("name", <TextBox />, true, this.ta("name"))}
        </div>
        <div className="col-md-4">
          {this.generateFormField("rank", <TextBox />, true, this.ta("rank"))}
        </div>
      </div>
    );
  }
}
