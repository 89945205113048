import BaseModal from '@component/BaseModal';

import Button, { ButtonVariant } from '@eman/emankit/Button';
import EventBus, { SHOW_TOAST } from '@util/EventBus';
import ValidationManager from "@util/Form/ValidationManager";
import { localize } from "@util/Localization";
import React from 'react';

export default abstract class CreateModal<TModel extends models.Base> extends BaseModal<TModel> {

  modalWidth = 480;
  suffix = 'create';

  abstract vm: ViewModel.Create<TModel>;

  componentDidMount(): void {
    this.vm.resetEntityAndErrors();
  }

  buttons() {
    return (
      <>
        <Button variant={ButtonVariant.SecondaryWhite} className="mr-12"
                onClick={this.dismiss}>{localize('form.cancel')}</Button>
        <Button variant={ButtonVariant.Primary} onClick={this.onSave}>{localize('form.save')}</Button>
      </>
    );
  }

  successMessage(): string {
    return localize('form.created');
  }

  onSave = async () => {
    ValidationManager.formValidation(this.vm.entity, this.vm.entity.validationOptions);

    if (this.vm.entity.errorCount !== 0) {
      return
    }

    const id = await this.vm.create();

    if (id) {
      EventBus.trigger(SHOW_TOAST, this.successMessage());
      this.onClose(id);
    }

    return status;
  };

  dismiss = () => {
    this.onClose();
  }

}
