import { observer } from "mobx-react";
import React from "react";

import BaseForm from "@component/BaseForm";
import EquipmentTypeFormVM from "@vm/Form/EquipmentType";
import EquipmentType from "@model/EquipmentType";
import { SelectBox } from "@util/Form";

interface EquipmentTypeFormProps {
  vm: EquipmentTypeFormVM;
}

@observer
export default class EquipmentTypeForm extends BaseForm<
  EquipmentType,
  EquipmentTypeFormProps
> {
  modelName = "equipmentType";

  renderFormBody() {
    return (
      <div className="row">
        <div className="col-md-12">
          {this.generateFormField(
            "equipmentGroupId",
            <SelectBox options={this.props.vm.options} />,
            true,
            this.ta("equipmentGroupId")
          )}
        </div>
      </div>
    );
  }
}
