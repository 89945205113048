import { confirmYesNo } from '@eman/emankit/ConfirmAlert';
import React from 'react';

import Button, { ButtonVariant } from '@eman/emankit/Button';

import BaseModal from '@component/BaseModal';
import EventBus, { SHOW_TOAST } from '@util/EventBus';
import ValidationManager from "@util/Form/ValidationManager";
import { localize } from "@util/Localization";

export default abstract class EditModal<TModel extends models.Base> extends BaseModal<TModel> {

  modalWidth = 480;
  suffix = 'edit';

  abstract vm: ViewModel.Edit<TModel>;

  get hasDelete() {
    return false;
  }

  constructor(props: PropsFromRouter) {
    super(props);
    this.setIdFromParams();
  }

  getId() {
    const params = this.props.match ? this.props.match.params : {};
    // tslint:disable-next-line: no-string-literal
    const key = `${this.vm.repository['modelName']}_id`; // TODO: This hack I personaly dont like it... but it will works for sub-show / list vm either..
    return params[key];
  }

  componentDidUpdate() {
    this.setIdFromParams();
  }

  setIdFromParams() {
    this.vm.init(this.getId());
  }

  buttons() {
    const actionButtons = (
      <>
        <Button variant={ButtonVariant.SecondaryWhite} className="mr-12" onClick={this.dismiss}>{localize('form.cancel')}</Button>
        <Button variant={ButtonVariant.Primary} onClick={this.onSave}>{localize('form.save')}</Button>
      </>
    );

    if (this.hasDelete) {
      return (
        <div className="row">
          <div className="col-6" style={{textAlign: 'left'}}>
            <Button variant={ButtonVariant.Dangerous} onClick={this.onDelete}>{localize('form.delete')}</Button>
          </div>
          <div className="col-6">
            {actionButtons}
          </div>
        </div>
      );
    } else {
      return actionButtons;
    }
  }

  successMessage(): string {
    return localize('form.updated');
  }

  onSave = async () => {
    ValidationManager.formValidation(this.vm.entity, this.vm.entity.validationOptions);

    if (this.vm.entity.errorCount !== 0) {
      return
    }

    const status = await this.vm.update();
    if (status) {
      this.vm.entity.clearErrors();
      EventBus.trigger(SHOW_TOAST, this.successMessage());
      this.onClose();
    }

    return status;
  };

  onDelete = async () => {
    confirmYesNo(localize('form.delete_confirm'), async () => {
      const status = await this.vm.destroy();

      if (status) {
        EventBus.trigger(SHOW_TOAST, localize('form.deleted'));
        this.onClose();
      }

      return status;
    });
  };

  dismiss = () => {
    this.onClose();
  }

}
