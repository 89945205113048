const LOCALIZATIONS = {

  'project.title': 'ŠKODA Service center',
  'footer': 'Copyright ©ŠKODA Auto a.s. 2020. All rights reserved - [www.skoda-auto.com](https://www.skoda-auto.com/)',

  'uploader': {
    text: 'test',
    or: 'test2',
    button: 'Browse',
  },

  'filters': {
    search: 'Search',
    all: 'All',
    selectAll: 'Select All',
    selectNothing: 'Select Nothing',
    confirm: 'Confirm',
    foundResults: 'Found Results',
    empty: 'Empty',
    loadingRow: 'Loading...',
    editFilters: 'Edit filters',
    resetFilters: 'Clear filters',
    resetFilter: 'Clear filter',
    from: 'from',
    to: 'to',
  },

  'UIKit': {
    date_picker: {
      strings: {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        weekdaysLong: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        firstDayOfWeek: 0,
        todayButton: 'Today',
        fromDate: 'From',
        toDate: 'To',
      },
      format: 'dd. MM. yyyy',
    },
    pagination: {
      next: 'Next',
      previous: 'Previous',
      showed: 'Records per page',
      from: 'From',
      records: 'records',
    },
    datatable: {
      display: {
        newDisplay: 'New',
        inputName: 'Input name',
        save: 'save',
        cancel: 'Cancel',
        deleteMessage: 'Delete message',
        notSelected: 'Not selected',
        saveDisplay: 'Save',
        removeDisplay: 'Remove',
        defaultGroup: 'Default group',
        usersGroup: 'Users group',
      },
      filters: {
        search: 'Search',
        all: 'All',
        selectAll: 'Select All',
        selectNothing: 'Select Nothing',
        confirm: 'Confirm',
        foundResults: 'Found Results',
        empty: 'Choose',
        editFilters: 'Edit filters',
        resetFilters: 'Clear filters',
        resetFilter: 'Clear filter',
        from: 'from',
        to: 'to',
      },
      emptyRow: 'Empty',
      export: {
        title: 'Export',
        body: 'Body',
        confirm: 'Confirm',
      },
      editColumns: 'Columns',
      openstate: {
        opened: 'Opened',
        closed: 'Closed',
      },
    },
    confirmation: {
      yes: 'Yes',
      no: 'No',
    },
    autocomplete: {
      headerText: 'Header',
      deleteSuggestionsText: 'Delete suggestions',
    },
    input_container: {
      optional_text: 'Optional',
      required_text: '',
    },
    address_picker: {
      right_selected_address: 'Right address',
    },
    multiselect: {
      search: 'Search',
      selectValues: 'Select values',
      selected: 'Selected',
      empty: 'Empty',
    },
    select: {
      select: 'Select',
      empty: 'Empty',
    },
  },

  "form.cancel": "Cancel",
  "form.save": "Save",

  "menu.groups": "Groups",
  "menu.types": "Types",

  "equipmentGroup.equipmentGroupId": "ID",
  "equipmentGroup.name": "Name",
  "equipmentGroup.rank": "Rank",
  "equipmentGroup.registeredOn": "Registered",

  "equipmentGroup.delete.title": "Delete group",
  "equipmentGroup.delete.message": "Are you sure want to delete this group?",
  "equipmentGroup.delete.cancel": "Cancel",
  "equipmentGroup.delete.ok": "OK",

  "equipmentGroup.table.title": "Equipment groups",
  "equipmentGroup.table.add": "Add new",
  "equipmentGroup.table.noResults": "No results found.",

  "equipmentGroup.create.title": "Create new group",
  "equipmentGroup.edit.title": "Edit group",

  "equipmentType.equipmentTypeId": "ID",
  "equipmentType.equipmentTypeName": "Name",
  "equipmentType.equipmentGroupId": "Group",

  "equipmentType.table.title": "Equipment types",
  "equipmentType.table.add": "Add new",
  "equipmentType.table.noResults": "No results found.",

  "equipmentType.create.title": "Create new type",
  "equipmentType.edit.title": "Edit type",
};

export default LOCALIZATIONS;
