import { classToPlain } from "@eman/class-transformer";
import EquipmentGroup from "@model/EquipmentGroup";
import ApiClient from "@util/ApiClient";
import EventBus, {
  ENTITY_CREATED,
  ENTITY_DELETED,
  ENTITY_UPDATE,
} from "@util/EventBus";
import { injectable } from "inversify";
import BaseRepository from "./BaseRepository";

@injectable()
export default class EquipmentGroupRepository extends BaseRepository<
  EquipmentGroup
> {
  get classModelName(): string {
    return "equipmentGroup";
  }

  constructor() {
    super(EquipmentGroup, "admin/equipment_groups", "equipment_group");
  }

  create(object: EquipmentGroup): Promise<ApiResponse<EquipmentGroup>> {
    const config = {
      url: "admin/equipment_group",
      method: "POST" as "POST",
      id: `CREATING_${this.modelName.toUpperCase()}`,
      data: classToPlain(object),
    };

    return ApiClient.fetchResponse(config).then((response) => {
      if (response.original) {
        EventBus.trigger(ENTITY_CREATED, {
          identificator: this.classModelName,
          id: response.original.id,
        });
      }

      return response;
    });
  }

  update(id: number | string | null, object: EquipmentGroup, params: any = {}) {
    const config = {
      url: `admin/equipment_group`,
      method: "PUT" as "PUT",
      id: `UPDATING_${this.modelName.toUpperCase()}`,
      params,
      data: classToPlain(object),
    };

    return ApiClient.fetchResponse(config).then((response) => {
      EventBus.trigger(ENTITY_UPDATE, {
        identificator: this.classModelName,
        id,
      });

      return response;
    });
  }

  destroy(id: number | string | EquipmentGroup): Promise<boolean> {
    const config = {
      url: `admin/equipment_group/${id}`,
      method: "DELETE" as "DELETE",
      id: `DELETE_${this.modelName}`,
    };

    return ApiClient.fetchResponse(config).then((response) => {
      if (response.status) {
        EventBus.trigger(ENTITY_DELETED, {
          identificator: this.classModelName,
          id,
        });
      }

      return response.status;
    });
  }
}
