import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { SynchronizedHistory } from "mobx-react-router";
import React from "react";

import PageHeader from "@eman/emankit/Header";

import BaseComponent from "@component/BaseComponent";
import AppMenu from "@component/Menu";

import "./styles.scss";

export interface HeaderState {
  active?: string;
}

@observer
export default class Header extends BaseComponent<{}, HeaderState> {
  unregisterSubscriber: UnregisterCallback;

  state = {
    active: undefined,
  };

  componentDidMount() {
    const history = this.router.history as SynchronizedHistory;

    this.unregisterSubscriber = history.subscribe((location, action) => {
      const paths = location.pathname.split("/");
      if (paths.length) {
        this.setState({ active: paths[1] });
      } else {
        this.setState({ active: undefined });
      }
    });
  }

  componentWillUnmount() {
    const history = this.router.history as SynchronizedHistory;
    history.unsubscribe = this.unregisterSubscriber;
  }

  handleLogout = () => {
    this.user.logoutUser();
  };

  render() {
    if (!this.router) {
      return null;
    }

    const profileData = this.user.isLoggedIn
      ? {
          items: [],
          username: `${this.user.entity.firstName} ${this.user.entity.lastName}`,
          onChange: () => this.handleLogout,
        }
      : undefined;

    return (
      <PageHeader
        // TODO: enable in admin app
        showBurger={this.user.isLoggedIn}
        showLogo={this.user.isLoggedIn}
        showProject={!this.user.isLoggedIn}
        project={this.user.applicationName}
        profile={profileData}
        locationListener={this.router.history.listen}
        rightMenuItems={
          <div className="emankit__menu-item">
            <a
              target="_blank"
              href={`${process.env.REACT_APP_API_BASE}reports/per_annum`}
            >
              Download report
            </a>
          </div>
        }
      >
        <AppMenu />
      </PageHeader>
    );
  }
}
