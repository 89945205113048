import EquipmentType from "@model/EquipmentType";
import EventBus, { SCROLL_TOP } from "@util/EventBus";
import EditViewModel from "@vm/Edit/EditViewModel";
import { inject, injectable } from "inversify";

import TYPES from "../../inversify.types";

@injectable()
export default class EquipmentTypeEditVM extends EditViewModel<
  EquipmentType,
  Repository<EquipmentType>
> {
  constructor(
    @inject(TYPES.EquipmentTypeRepository)
    repository: Repository<EquipmentType>,
  ) {
    super(EquipmentType, repository);
  }

  async fetchItem(scrollTop: boolean = true): Promise<number | string> {
    this.currentlyFetching = true;

    const response = await this.repository.fetchList({});
    if (response && response.list) {
      const foundItem = response.list.find(
        (g) => g.equipmentTypeId === this.id
      );
      if (foundItem) {
        this.setEntity(foundItem);
      }
    }

    if (scrollTop && this.scroll) {
      EventBus.trigger(SCROLL_TOP);
    }

    // Create or update associated VMs
    this.createOrUpdateAssociatedVMS();

    this.currentlyFetching = false;

    return this.id;
  }
}
