import React from "react";
import { observer } from "mobx-react";

import EquipmentType from "@model/EquipmentType";
import EquipmentTypeListVM from "@vm/List/EquipmentType";

import BaseList from "@component/BaseList";
import Icon, { IconType } from "@eman/emankit/Icon";
import { HeaderProps } from "@eman/emankit/DataTable/HeaderBar";

import { lazyInject, TYPES } from "../../inversify.config";

@observer
export default class EquipmentTypeList extends BaseList<
  EquipmentType,
  EquipmentTypeListVM,
  {},
  {}
> {
  modelName = "equipmentType";
  emptyRow = this.tg("table.noResults");
  searchable = false;
  selectableRows = false;

  @lazyInject(TYPES.EquipmentTypeList)
  vm: EquipmentTypeListVM;

  constructor(props: any) {
    super(props);

    this.state = {};
  }

  add = () => {
    this.router.pageLink(this.uriHelper.new_types());
  };

  edit = (type: EquipmentType) => () => {
    this.router.pageLink(this.uriHelper.edit_types(type.equipmentTypeId));
  };

  headerProps(): HeaderProps | undefined {
    return {
      text: this.tg("table.title"),
    };
  }

  renderRow = (item: EquipmentType, column: string) => {
    switch (column) {
      case "equipmentGroupId":
        return this.vm.getGroupNameById(item.equipmentGroupId);
      case "actions":
        return (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Icon
              icon={IconType.SoconEdit}
              height={20}
              width={20}
              key="market_edit"
              onClick={this.edit(item)}
            />
          </div>
        );
      default:
        return item[column];
    }
  };

  paginationProps() {
    return {
      pageSize: this.vm.pagination.pageSize,
      page: this.vm.pagination.page,
      totalRecords: this.vm.total,
      onPageChange: this.onPageChange,
      label: "results",
    };
  }

  filterProps() {
    return {
      hideFilterSettings: true,
      hideColumnSettings: true,
      hideReset: true,
      filters: [],
    };
  }

  dataProps() {
    const columns = [
      this.createField("equipmentTypeId", {
        width: 40,
        tooltip: true,
        nosort: true,
      }),
      this.createField("equipmentTypeName", {
        width: 300,
        tooltip: true,
        nosort: true,
      }),
      this.createField("equipmentGroupId", {
        width: 300,
        tooltip: true,
        nosort: true,
      }),
      this.createField("actions", { width: 80, nosort: true, hideLabel: true }),
    ];

    if (this.vm.columns.length === 0) {
      this.vm.setColumns(columns.map((column) => column.id));
    }

    return {
      columns,
      value: this.renderRow,
      loading: false,
      fixedHeader: true,
    };
  }
}
