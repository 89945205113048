import format from "date-fns/format";
import parse from "date-fns/parse";

export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXXX";
export const FE_DATE_FORMAT = "dd-MM-yyyy";

export const formatDate = (date: string | Date, dateFormat?: string) => {
  let parsedDate: Date;
  if (typeof date === "string") {
    parsedDate = new Date(date);
  } else {
    parsedDate = date;
  }

  return format(parsedDate, dateFormat || DEFAULT_DATE_FORMAT);
};

export const formatRequestDate = (date: string | Date) =>
  formatDate(date, "yyyy-MM-dd");

export const parseDate = (
  date: string,
  dateFormat?: string,
  baseDate?: Date
): Date => {
  return parse(date, dateFormat || DEFAULT_DATE_FORMAT, baseDate || new Date());
};
