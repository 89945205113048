import BaseComponent from "@component/BaseComponent";
import Footer from "@eman/emankit/Footer";
import { localize } from "@util/Localization";
import { observer } from "mobx-react";
import React from "react";
import ReactMarkdown from "react-markdown";

@observer
export default class PageFooter extends BaseComponent {

  render() {
    return (
      <Footer text={(
        <ReactMarkdown source={`${localize('footer')} v${process.env.REACT_APP_VERSION}`}/>
      )}/>
    );
  }
}