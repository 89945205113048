import { inject, injectable } from "inversify";

import EquipmentGroup from "@model/EquipmentGroup";
import ListViewModel from "@vm/List/ListViewModel";
import TYPES from "../../inversify.types";

@injectable()
export default class EquipmentGroupListVM extends ListViewModel<
  EquipmentGroup,
  Repository<EquipmentGroup>
> {
  static defaults = {
    order: {
      field: "last_name",
      direction: "asc",
    },
    columns: [],
    visibleFilters: [],
  };

  constructor(
    @inject(TYPES.EquipmentGroupRepository)
    repository: Repository<EquipmentGroup>
  ) {
    super(repository);
  }
}
