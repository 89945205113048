import ResourceComponent from '@component/ResourceComponent';

import Menu from '@eman/emankit/Menu';
import { MenuItemProps } from '@eman/emankit/Menu/MenuItem';
import { UnregisterCallback } from 'history';
import { observer } from 'mobx-react';
import { SynchronizedHistory } from 'mobx-react-router';
import React from 'react';
import { Role } from '@model/BaseUser';

export interface MenuComponentState {
  active?: string;
}

@observer
export default class MenuComponent extends ResourceComponent<{}, MenuComponentState> {

  get modelName(): string {
    return "menu";
  }

  unregisterSubscriber: UnregisterCallback;

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const history = this.router.history as SynchronizedHistory;
    this.unregisterSubscriber = history.subscribe((location, action) => {
      const paths = location.pathname.split('/');
      if (paths.length) {
        this.setState({ active: paths[1] });
      } else {
        this.setState({ active: undefined });
      }
    });
  }

  componentWillUnmount() {
    const history = this.router.history as SynchronizedHistory;
    history.unsubscribe = this.unregisterSubscriber;
  }


  changeMenu = (key: string) => {
    this.router.pageLink(key);
  };

  render() {
    const { active } = this.state;
    const menuItems: MenuItemProps[] = [];

    if (this.user.isLoggedIn) {
      if (this.user.role === Role.ADMIN) {
        menuItems.push({
          id: this.uriHelper.groups_uri(),
          label: this.ta("groups")
        });
      }
      menuItems.push({
        id: this.uriHelper.types_uri(),
        label: this.ta("types")
      });
    }

    return <Menu items={menuItems} onChange={this.changeMenu} active={`/${active}`} />
  }
}
